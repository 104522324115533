import { Rect } from "react-konva";

const GoalLines = ({ fieldDimensions }) => {
  const endzoneHeight = fieldDimensions.height * (20 / 150);
  return (
    <>
      {[endzoneHeight, fieldDimensions.height - endzoneHeight].map(
        (y, index) => (
          <Rect
            key={index}
            x={0}
            y={y}
            width={fieldDimensions.width}
            height={3}
            fill="white"
          />
        )
      )}
    </>
  );
};
export default GoalLines;