import { Typography, Box } from "@mui/material";
import React from 'react';
import { TextField, Button } from "@mui/material";
import { confirmSignUp } from "aws-amplify/auth";


const ConfirmSignUpForm = ({ state, setState }) => {

    const { email, confirmationCode } = state;

    const handleConfirmSignUp = () => {
        confirmSignUp({
            confirmationCode,
            username: email,
        }).then(() => {
            window.location.href = '/app';
        }).catch((error) => {
        });
    };

    return (

        <Box display="flex" flexDirection="column" alignItems="center" marginTop="100px" padding="45px" style={{ backgroundColor: '#191F24', border: '2px solid #ffffff', borderRadius: '10px', minWidth: '350px' }}>
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '60px', color: 'white', fontWeight: "bold" }}>
                clipr
            </Typography>
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '30px', color: 'white', marginTop: "50px", marginBottom: "50px" }}>
                Confirm Email Address
            </Typography>

            <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                value={email}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white', backgroundColor: '#101417' } }}
            />
            <TextField
                label="Confirmation Code"
                variant="outlined"
                margin="normal"
                fullWidth
                value={confirmationCode}
                onChange={(e) => setState({ ...state, confirmationCode: e.target.value })}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white', backgroundColor: '#101417' } }}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmSignUp}
                style={{ marginTop: '20px', width: '100%' }}
            >
                Continue
            </Button>
        </Box>);
};

export default ConfirmSignUpForm;