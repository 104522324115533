import { Typography, Box } from "@mui/material";
import React, { } from 'react';
import { TextField, Button } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import { signIn } from "aws-amplify/auth";


const SignUpForm = ({ state, setState }) => {

    const { email, password } = state;

    const handleSignIn = () => {
        signIn({
            username: email,
            password: password,
        }).then(() => {
            window.location.href = '/app';
        }).catch((error) => {
            alert(error.message);
        });
    };

    return (

        <Box display="flex" flexDirection="column" alignItems="center" marginTop="100px" padding="45px" style={{ backgroundColor: '#191F24', border: '2px solid #ffffff', borderRadius: '10px', minWidth: '350px' }}>
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '60px', color: 'white', fontWeight: "bold" }}>
                clipr
            </Typography>
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '30px', color: 'white', marginTop: "50px", marginBottom: "50px" }}>
                Sign In
            </Typography>

            <TextField
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                value={email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white', backgroundColor: '#101417' } }}
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                value={password}
                onChange={(e) => setState({ ...state, password: e.target.value })}
                InputLabelProps={{ style: { color: 'white' } }}
                InputProps={{ style: { color: 'white', backgroundColor: '#101417', } }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSignIn}
                style={{ marginTop: '20px', width: '100%' }}
            >
                Continue
            </Button>
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '16px', color: 'white', marginTop: '30px' }}>
                Don't have an account? <a href="/sign-up" style={{ color: '#095E89', textDecoration: 'none' }}>Sign Up</a>
            </Typography>
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '16px', marginTop: '80px', color: 'white' }}>
                Or Sign In With
            </Typography>
            <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '20px', width: '100%' }}
            >
                <GoogleIcon style={{ marginRight: '10px' }} />
                Google
            </Button>
        </Box>
    );
};

export default SignUpForm;