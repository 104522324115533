import { Box } from "@mui/material";
import React, { useState } from 'react';
import SignInForm from "./SignInForm";


const SignUp = () => {
    const [state, setState] = useState({ email: '', password: '' });

    return (
        <Box display="flex" flexDirection="column" alignItems="center" style={{ minHeight: '80vh', backgroundColor: '#101417', border: '2px solid #ffffff' }}>
            <SignInForm state={state} setState={setState} />
        </Box>
    );
}

export default SignUp;
