import React, { useEffect, useState } from "react";
import { Stage, Layer, Circle, } from "react-konva";
import { Box } from "@mui/material";
import Grass from "./Grass";
import GoalLines from "./GoalLines";
import HashMarks from "./HashMarks";
import Numbers from "./Numbers";
import YardLines from "./YardLines";
import LineOfScrimmage from "./LineOfScrimmage";
import Shapes from "./Shapes";

const Field = ({ isAddingShape, onPlaceShape, shapes }) => {
  const [fieldDimensions, setFieldDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [StageDimensions, setStageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scale, setScale] = useState(1);
  const [shapePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [isShapeVisible, setIsCircleVisible] = useState(false);


  useEffect(() => {
    const updateStageAndFieldDimensions = () => {
      setStageDimensions({
        width: window.innerWidth * 0.7,
        height: (window.innerWidth * 0.35 * 150) / 65,
      });
      setFieldDimensions({
        width: window.innerWidth * 0.7,
        height: (window.innerWidth * 0.7 * 150) / 65,
      });
    };

    updateStageAndFieldDimensions();
    window.addEventListener("resize", updateStageAndFieldDimensions);

    return () =>
      window.removeEventListener("resize", updateStageAndFieldDimensions);
  }, []);

  const handleWheel = (e) => {
    e.evt.preventDefault();
    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Add constraints on the upper and lower value a scale can be
    const minScale = 0.5;
    const maxScale = 2;
    newScale = Math.max(minScale, Math.min(maxScale, newScale));

    setScale(newScale);

    stage.scale({ x: newScale, y: newScale });

    const stageCenter = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    const newPos = {
      x: stageCenter.x - (stageCenter.x - stage.x()) * (newScale / oldScale),
      y: stageCenter.y - (stageCenter.y - stage.y()) * (newScale / oldScale),
    };

    stage.position(newPos);
    stage.batchDraw();
  };

  const handleMouseMove = (e) => {
    if (isAddingShape) {
      const stage = e.target.getStage();
      const mousePos = stage.getPointerPosition();
      setCirclePosition(mousePos);
      setIsCircleVisible(true);
    }
  };

  const handleClick = () => {
    if (isAddingShape) {
      onPlaceShape({ shape: "circle", x: shapePosition.x, y: shapePosition.y, fill: "red", radius: 10 });
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stage
        width={StageDimensions.width}
        height={StageDimensions.height}
        style={{ border: "1px solid black" }}
        onWheel={handleWheel}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
      >
        <Layer scaleX={scale} scaleY={scale}>
          <Grass fieldDimensions={fieldDimensions} />
          <GoalLines fieldDimensions={fieldDimensions} />
          <HashMarks fieldDimensions={fieldDimensions} />
          <YardLines fieldDimensions={fieldDimensions} />
          <Numbers fieldDimensions={fieldDimensions} />
          <LineOfScrimmage fieldDimensions={fieldDimensions} location={42} />
          <Shapes shapes={shapes} />
          {isAddingShape && isShapeVisible && (
            <>
              <Circle
                x={shapePosition.x}
                y={shapePosition.y}
                radius={10}
                fill="red"
              />
            </>
          )}
        </Layer>
      </Stage>
    </Box>
  );
};
export default Field;