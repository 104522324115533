import { Rect } from "react-konva";

const HashMarks = ({ fieldDimensions }) => {
  const xRange = [
    fieldDimensions.width * (0 / 65),
    fieldDimensions.width * (24 / 65),
    fieldDimensions.width * (41 / 65),
    fieldDimensions.width * (64 / 65),
  ];
  const yRange = Array.from({ length: 109 }, (_, i) => i + 21);
  return (
    <>
      {xRange.map((x) =>
        yRange.map((y) => (
          <Rect
            key={`${x}-${y}`}
            x={x}
            y={fieldDimensions.height * (y / 150)}
            width={fieldDimensions.width * (1 / 65)}
            height={fieldDimensions.height * (0.25 / 150)}
            fill="white"
          />
        ))
      )}
    </>
  );
};
export default HashMarks;