import React from 'react';
import { Outlet } from 'react-router-dom';
import MenuBar from './MenuBar';

const Layout = () => {
    return (
        <div>
            <MenuBar />
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;