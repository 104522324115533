import CreatePlay from "./pages/CreatePlay";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AppLayout from "./layouts/AppLayout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    }
  }
})


const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/sign-in" element={<SignIn />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route path="/app/" element={<AppLayout />}>
          <Route exact path="/app/create-play" element={<CreatePlay />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
