import { Rect } from "react-konva";

const Grass = ({ fieldDimensions }) => {
  return (
    <Rect
      width={fieldDimensions.width}
      height={fieldDimensions.height}
      fill="green"
    />
  );
};
export default Grass;