import { Button, TextField, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import Field from "../../components/Field";
import EditingMenu from "./EditingMenu";

const onClickEdit = (state, setState) => {
  setState({ ...state, editing: !state.editing });
};

const onNameChange = (e, state, setState) => {
  setState({ ...state, name: e.target.value });
};



const CreatePlay = () => {
  const [state, setState] = useState({
    editing: false,
    name: "Untitled Play",
    shapes: [],
    isAddingShape: false,
  });

  const onPlaceShape = (shape) => {
    setState({
      ...state,
      isAddingShape: !state.isAddingShape,
      shapes: [...state.shapes, shape],
    });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center" paddingTop="30px">
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="70%">
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"  >
            <Button
              variant="text"
              color="primary"
              onClick={() => onClickEdit(state, setState)}
            >
              {state.editing ? "Save" : "Edit"}
            </Button>
          </Box>
          {state.editing ? (
            <TextField
              disabled={!state.editing}
              onChange={(e) => onNameChange(e, state, setState)}
              defaultValue={state.name}
              style={{ flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', }}
            />
          ) : (
            <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold" }}>
              {state.name}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop="20px"
      >
        {state.editing && (
          <EditingMenu
            editing={state.editing}
            parentState={state}
            setParentState={setState}
          />
        )}
        <Field isAddingShape={state.isAddingShape} onPlaceShape={onPlaceShape} shapes={state.shapes} />
      </Box>
    </Box>
  );
};
export default CreatePlay;