import { Typography, Box } from "@mui/material";


const Home = () => {
    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" paddingTop="30px">
                <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold" }}>
                    Home
                </Typography>
            </Box>
        </Box>
    );
}

export default Home;