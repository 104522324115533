import { Circle } from "react-konva";

const Shapes = ({ shapes }) => {
  return (
    <>
      {shapes.map((shape, index) => {
        return (
          <Circle
            key={index}
            x={shape.x}
            y={shape.y}
            radius={shape.radius}
            fill={shape.fill}
          />
        );
      })}
    </>);
};
export default Shapes;