import { Box, Chip } from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import NorthIcon from "@mui/icons-material/North";

const onAddShape = (state, setState) => {
  setState({ ...state, isAddingShape: !state.isAddingShape });
};

const EditingMenu = ({ parentState, setParentState }) => {
  const chipStyle = { margin: "2px" };
  return (
    <Box display="flex" flexDirection="row" marginY="10px">
      <Box display="flex" flexDirection="row" marginX="25px">
        <Chip sx={chipStyle} icon={<AdsClickIcon />} />
      </Box>
      <Box display="flex" flexDirection="row" marginX="25px">
        <Chip
          sx={chipStyle}
          onClick={() => onAddShape(parentState, setParentState)}
          icon={<PanoramaFishEyeIcon />}
        />
      </Box>
      <Box display="flex" flexDirection="row" marginX="25px">
        <Chip sx={chipStyle} icon={<NorthIcon />} />
      </Box>
    </Box>
  );
};
export default EditingMenu;