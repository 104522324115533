import { Rect } from "react-konva";

const LineOfScrimmage = ({ fieldDimensions, location }) => {
  const y = fieldDimensions.height * (location / 150);
  return (
    <>
      <Rect
        x={0}
        y={y}
        width={fieldDimensions.width}
        height={fieldDimensions.height * (0.25 / 150)}
        fill="grey"
      />
    </>
  );
};
export default LineOfScrimmage;