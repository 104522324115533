import { Text } from "react-konva";

const Numbers = ({ fieldDimensions }) => {
  const xRange = [
    fieldDimensions.width * (12 / 65),
    fieldDimensions.width * (53 / 65),
  ];
  const yRange = Array.from({ length: 11 }, (_, i) => i * 10 + 20);
  return (
    <>
      {xRange.map((x) =>
        yRange.map((y) => (
          <Text
            key={`${x}-${y}`}
            text={"00"}
            x={x}
            y={fieldDimensions.height * ((y - 1) / 150)}
            width={fieldDimensions.width * (2 / 65)}
            height={fieldDimensions.height * (2 / 150)}
            fontSize={30 * (fieldDimensions.width / 700)} // Adjust font size based on field width
            fill="white"
          />
        ))
      )}
    </>
  );
};
export default Numbers;