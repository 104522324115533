import { Rect } from "react-konva";

const YardLines = ({ fieldDimensions }) => {
  const xRange = [0];
  const yRange = Array.from({ length: 22 }, (_, i) => i * 5 + 20);
  return (
    <>
      {xRange.map((x) =>
        yRange.map((y) => (
          <Rect
            key={`${x}-${y}`}
            x={x}
            y={fieldDimensions.height * (y / 150)}
            width={fieldDimensions.width}
            height={fieldDimensions.height * (0.25 / 150)}
            fill="white"
          />
        ))
      )}
    </>
  );
};
export default YardLines;