import { Box } from "@mui/material";
import React, { useState } from 'react';
import ConfirmSignUpForm from "./ConfirmSignUpForm";
import SignUpForm from "./SignUpForm";


const SignUp = () => {
    const [state, setState] = useState({ email: '', password: '', confirmationCode: '', didSignUp: false, didConfirm: false, });

    return (
        <Box display="flex" flexDirection="column" alignItems="center" style={{ minHeight: '100vh', backgroundColor: '#101417', border: '2px solid #ffffff' }}>
            {state.didSignUp ? <ConfirmSignUpForm state={state} setState={setState} /> : <SignUpForm state={state} setState={setState} />}
        </Box>
    );
}

export default SignUp;
